export default[
  {
    path: "/hazardous",
    name: "hazardous",
    meta:{
      title: '首页',
      scroll: true
    },
    component: () => import("../../views/layout.vue"),
    redirect: '/hazardous/index',
    children: [
      {
        path: "/hazardous/index",
        name: "hazardousHome",
        meta:{
          title: '首页',
          scroll: true
        },
        component: () => import("../../views/hazardous/home.vue"),
      },
      {
        path: "/hazardous/waste",
        name: "hazardousWaste",
        meta:{
          title: '危废管理',
          scroll: true,
          permission: 'hw'
        },
        component: () => import("../../views/hazardous/waste/index.vue"),
        redirect: '/hazardous/waste/delivery',
        children: [
          {
            path: "/hazardous/waste/delivery",
            name: "hazardousDelivery",
            meta:{
              title: '危废产生台账',
              scroll: true,
              permission: 'hw:delivery'
            },
            component: () => import("../../views/hazardous/waste/delivery.vue"),
          },
          {
            path: "/hazardous/waste/deliveryDetail",
            name: "hazardousDeliveryDetail",
            meta:{
              title: '危废产生台账',
              scroll: true
            },
            component: () => import("../../views/hazardous/waste/deliveryDetail.vue"),
          },
          {
            path: "/hazardous/waste/storageIn",
            name: "hazardousStorageIn",
            meta:{
              title: '危废入库台账',
              scroll: true,
              permission: 'hw:storageIn'
            },
            component: () => import("../../views/hazardous/waste/storageIn.vue"),
          },
          {
            path: "/hazardous/waste/storageInDetail",
            name: "hazardousStorageInDetail",
            meta:{
              title: '危废入库台账',
              scroll: true
            },
            component: () => import("../../views/hazardous/waste/storageInDetail.vue"),
          },
          {
            path: "/hazardous/waste/storageOut",
            name: "hazardousStorageOut",
            meta:{
              title: '危废出库台账',
              scroll: true,
              permission: 'hw:storageOut'
            },
            component: () => import("../../views/hazardous/waste/storageOut.vue"),
          },
          {
            path: "/hazardous/waste/storageOutDetail",
            name: "hazardousStorageOutDetail",
            meta:{
              title: '危废出库台账',
              scroll: true
            },
            component: () => import("../../views/hazardous/waste/storageOutDetail.vue"),
          },
          {
            path: "/hazardous/waste/success",
            name: "hazardousSuccess",
            meta:{
              title: '操作成功',
              scroll: true
            },
            component: () => import("../../views/hazardous/waste/success.vue"),
          },
          {
            path: "/hazardous/waste/dispose",
            name: "hazardousDispose",
            meta:{
              title: '自行利用处置台账',
              scroll: true,
              permission: 'hw:storageUse'
            },
            component: () => import("../../views/hazardous/waste/dispose.vue"),
          },
          {
            path: "/hazardous/waste/disposeDetail",
            name: "hazardousDisposeDetail",
            meta:{
              title: '自行利用处置台账',
              scroll: true
            },
            component: () => import("../../views/hazardous/waste/disposeDetail.vue"),
          },
          {
            path: "/hazardous/waste/plan",
            name: "hazardousPlan",
            meta:{
              title: '管理计划',
              scroll: true,
              permission: 'hw:plan',
              keepAlive: true
            },
            component: () => import("../../views/hazardous/waste/plan.vue"),
          },
          {
            path: "/hazardous/waste/planDetailList",
            name: "hazardousPlanDetailList",
            meta:{
              title: '计划列表',
              scroll: true,
            },
            component: () => import("../../views/hazardous/waste/planDetailList.vue"),
          },
          {
            path: "/hazardous/waste/planDetail",
            name: "hazardousPlanDetail",
            meta:{
              title: '计划列表',
              scroll: true,
            },
            component: () => import("../../views/hazardous/waste/planDetail.vue"),
          },
          {
            path: "/hazardous/waste/planWasteDetail",
            name: "hazardousPlanWasteDetail",
            meta:{
              title: '废料详情',
              scroll: true,
            },
            component: () => import("../../views/hazardous/waste/planWasteDetail.vue"),
          },
          {
            path: "/hazardous/waste/planSuccess",
            name: "hazardousPlanSuccess",
            meta:{
              title: '提交成功',
              scroll: true,
            },
            component: () => import("../../views/hazardous/waste/planSuccess.vue"),
          }
        ]
      },
      {
        path: "/hazardous/analysis",
        name: "hazardousAnalysis",
        meta:{
          title: '数据分析',
          scroll: true,
          permission: 'hw:summary'
        },
        component: () => import("../../views/hazardous/analysis/index.vue"),
        redirect: '/hazardous/analysis/monthReport',
        children: [
          {
            path: "/hazardous/analysis/monthReport",
            name: "hazardousMonthReport",
            meta:{
              title: '月报',
              scroll: true,
              permission: 'hw:summary:monthReport'
            },
            component: () => import("../../views/hazardous/analysis/monthReport.vue"),
          },
          {
            path: "/hazardous/analysis/monthDetailList",
            name: "hazardousMonthDetailList",
            meta:{
              title: '月报详情',
              scroll: true,
            },
            component: () => import("../../views/hazardous/analysis/monthDetailList.vue"),
          },
          {
            path: "/hazardous/analysis/monthDetail",
            name: "hazardousMonthDetail",
            meta:{
              title: '月报详情',
              scroll: true,
            },
            component: () => import("../../views/hazardous/analysis/monthDetail.vue"),
          }
        ]
      },
      {
        path: "/hazardous/setting",
        name: "hazardousSetting",
        meta:{
          title: '系统设置',
          scroll: true,
          permission: 'setting'
        },
        component: () => import("../../views/hazardous/setting/index.vue"),
        redirect: '/hazardous/setting/facility',
        children: [
          {
            path: "/hazardous/setting/facility",
            name: "hazardousFacility",
            meta:{
              title: '生产设施配置',
              scroll: true,
              permission: 'setting:deliveryFacility'
            },
            component: () => import("../../views/hazardous/setting/facility.vue"),
          },
          {
            path: "/hazardous/setting/useFacility",
            name: "hazardousUseFacility",
            meta:{
              title: '利用处置设施配置',
              scroll: true,
              permission: 'setting:useFacility'
            },
            component: () => import("../../views/hazardous/setting/useFacility.vue"),
          },
          {
            path: "/hazardous/setting/storageFacility",
            name: "hazardousStorageFacility",
            meta:{
              title: '贮存设施配置',
              scroll: true,
              permission: 'setting:storageFacility'
            },
            component: () => import("../../views/hazardous/setting/storageFacility.vue"),
          },
          {
            path: "/hazardous/setting/transfer",
            name: "hazardousTransfer",
            meta:{
              title: '接收单位配置',
              scroll: true,
              permission: 'setting:transfer'
            },
            component: () => import("../../views/hazardous/setting/transfer.vue"),
          }
        ]
      },
      {
        path: "/hazardous/settle",
        name: "hazardousSettle",
        meta:{
          title: '结账管理',
          scroll: true,
          permission: 'hw:settle'
        },
        component: () => import("../../views/hazardous/settle/index.vue"),
        redirect: '/hazardous/settle/wait',
        children: [
          {
            path: "/hazardous/settle/wait",
            name: "hazardousSettleWait",
            meta:{
              title: '待结账',
              scroll: true,
              permission: 'hw:settle:wait'
            },
            component: () => import("../../views/hazardous/settle/wait.vue")
          },
          {
            path: "/hazardous/settle/complete",
            name: "hazardousSettleComplete",
            meta:{
              title: '结账单',
              scroll: true,
              permission: 'hw:settle:complete'
            },
            component: () => import("../../views/hazardous/settle/complete.vue")
          },
          {
            path: "/hazardous/settle/completeDetail",
            name: "hazardousSettleCompleteDetail",
            meta:{
              title: '结账单详情',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/hazardous/settle/completeDetail.vue")
          },
          {
            path: "/hazardous/settle/success",
            name: "hazardousSettleSuccess",
            meta:{
              title: '待结账',
              scroll: true,
              permission: ''
            },
            component: () => import("../../views/hazardous/settle/success.vue")
          }
        ]
      },
    ]
  }
]
