import { createRouter, createWebHashHistory } from 'vue-router';
import { getItem, setItem } from '../assets/js/storage.js';
import routes from './model';
import { tool } from '@/stores/tool';
import mapMenu from '@/components/main/menu.js';
import { checkObj } from '@/utils';

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

var list = [];
mapMenu.SW.forEach(e => {
  list.push(e);
  if (e.children && e.children.length) {
    list = [...list, ...e.children];
  }
})
mapMenu.HW.forEach(e => {
  list.push(e);
  if (e.children && e.children.length) {
    list = [...list, ...e.children];
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let wasteType = tool().wasteType;
  let sideBar = tool().sideBar;
  let sideView = tool().sideView;
  var token = getItem('token');
  if (token) {
    if (to.path == '/login') {//token存在，并且是login页面
      if (sideBar && sideBar.SW && sideBar.SW.length) {
        const sideBarNav = getItem('sideBar').SW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/factory/index') {
          next('/factory');
        } else {
          next(obj.path);
        }
      } else {
        const sideBarNav = getItem('sideBar').HW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/hazardous/index') {
          next('/hazardous');
        } else {
          next(obj.path);
        }
      }
    } else {//token存在，不是login页面
      if (to.path == '/hazardous/index') {
        setItem('wasteType', 'HW');
        const sideBarNav = getItem('sideBar').HW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/hazardous/index') {
          next();
        } else {
          next(obj.path);
        }
      } else if (to.path == '/factory/index') {
        setItem('wasteType', 'SW');
        const sideBarNav = getItem('sideBar').SW[0];
        let obj = findPath(sideBarNav)
        if (obj.path == '/factory/index') {
          next();
        } else {
          next(obj.path);
        }
      } else {
        next();
      }
    }
  } else {
    if (to.path == '/login') {//token不存在，并且是login页面
      next();
    } else {//token不存在，不是login页面
      next('/login')
    }
  }
})

function findPath(sideBarNav) {
  const permission = sideBarNav.child && sideBarNav.child.length ? sideBarNav.child[0].permission : sideBarNav.permission;
  return list.find(o => o.permission === permission);
}

export default router
